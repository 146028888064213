import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, createApiInstance } from "api/httpCommon";
import { InferType } from "yup";
import { IReponse } from "interfaces/definition";
import { ScheduledSchema } from "schemas/formSchema";


type Programming = InferType<typeof ScheduledSchema>;

const apiV2 = createApiInstance('V2');

export const getNewsletterById = createAsyncThunk(
  "send/getNewsletterById",
  async (newsletterId: number): Promise<IReponse> => {
    const response = await api.get(`template/${newsletterId}`);
    return response.data as IReponse
  }
)

interface UpdateProgrammingResponse {
  data: any; // Define el tipo de datos que esperas en la respuesta
}

interface UpdateProgrammingError {
  message: string;
  // Añadir más campos si es necesario
}

export const updateProgramming = createAsyncThunk<UpdateProgrammingResponse, Programming,
  { rejectValue: UpdateProgrammingError }
>(
  "sends/updateProgramming",
  async (programing: Programming, { rejectWithValue }) => {
    try {
      const response = await api.put("schedule", programing)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
);

export const addPrograming = createAsyncThunk(
  "campaigns/addScheduled",
  async (program: Programming, { rejectWithValue }) => {
    try {
      const response = await api.post(`schedule`, program);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const removePrograming = createAsyncThunk(
  "campaigns/removeSchedule",
  async (programingId: string, { rejectWithValue }) => {
    try {
      const response = await api.delete(`schedule/${programingId}`);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const UNSUBSCRIBE = createAsyncThunk(
  "send/unsubscribe",
  async (unsubscribe: { email: string }, { rejectWithValue }) => {
    try {
      const response = await api.post(`/unsubscribe`, unsubscribe);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const SUSCRIPTIONPREFERENCES = createAsyncThunk(
  "send/preferences",
  async (preferences: { email: string, action: string, token: string }, { rejectWithValue }) => {
    try {
      const response = await apiV2.post(`/subscription-preferences`, preferences);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const URLSHORTER = createAsyncThunk(
  "shortener",
  async (postShort: { url: string }, { rejectWithValue }) => {
    try {
      const response = await apiV2.post(`/shortener`, postShort);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const GET_SOURCE_NUMBERS = createAsyncThunk(
  "profiles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiV2.get(`/whatsapp/profiles`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const GET_TEMPLATES = createAsyncThunk(
  "templates",
  async (phone_number: string, { rejectWithValue }) => {
    try {
      const response = await apiV2.get(`/whatsapp/${phone_number}/templates`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)

export const SEND_TEMPLATE_MESSAGE = createAsyncThunk(
  "whatsapp_send_template",
  async (data: { source: string, template: string, parameters: string[], destination: string }, { rejectWithValue }) => {
    try {
      const response = await apiV2.post("/whatsapp/send", data)
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || "Error desconocido");
    }
  }
)