import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import {
  EDIT_PROGRAMMING,
  GET_CAMPAIGNS,
  GET_CAMPAIGN_ID,
  GET_NEWSLETTER_APROVE,
  GET_SENDS_BY_CAMPAIGN_SIMPLE,
} from "api/queries";
import { useAppDispatch } from "store";
import { ScheduleForm, showLoading } from "components";
import { updateProgramming,addPrograming } from "features/sends/sendThunks";
import { InferType } from "yup";
import { ScheduledSchema } from "schemas/formSchema";


type Scheduled = InferType<typeof ScheduledSchema>;

const ProgrammingForm:React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const [defaultValue, setDefaultValue] = useState<Scheduled>();
  const [sendsByCampaign, setSendsByCampaign] = useState([]);
  const [editProgram, { data }] = useLazyQuery(EDIT_PROGRAMMING);
  const [selectedCampaign, setSelectedCampaign]= useState('')

  useEffect(() => {
    if (state != null) {
      editProgram({
        fetchPolicy:"network-only",
        variables: {
          filter: {
            identifier: state?.programming_id,
          },
          filterSendMethod:{
            programming_id:state?.programming_id
          }
        },
      });
    }
  }, [state, editProgram]);

  useEffect(() => {
    if (data && data.Programming && data.Programming.length > 0) {

      const { Programming, Send_method } = data;
      const [schedule] = Programming;
      const [sendMethod] = Send_method;
      
      schedule.send_method = sendMethod
      setDefaultValue(schedule);
    }
  }, [data]);

  const {
    loading: loadingCampaign,
    error: errorCampaign,
    data: listCampaign = [],
  } = useQuery(GET_CAMPAIGNS);

  const {
    loading,
    error,
    data: dataNewsletters = [],
  } = useQuery(GET_NEWSLETTER_APROVE, {
    fetchPolicy: "network-only",
  });

  if (loading && loadingCampaign) {
    return <div>Cargando</div>;
  }

  if (error || errorCampaign) {
    return <div>Something went wrong </div>;
  }

  const getProfiles = async (selectedOption: string) => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_SENDS_BY_CAMPAIGN_SIMPLE,
        variables: {
          filter: {
            campaign: selectedOption,
          },
          fetchPolicy: "no-cache",
        },
      });
      setSendsByCampaign(datosresponse.Sends);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  const getCampaignInfo = async (sendId: string) => {
    try {
      const { data: campaigInfo } = await client.query({
        query: GET_CAMPAIGN_ID,
        variables: {
          filter: {
            send: sendId,
          },
          fetchPolicy: "no-cache",
        },
      });

      const { identifier } = campaigInfo.Campaigns[0];
      setSelectedCampaign(identifier);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }


  };

  const onSubmit = (datos) => {
    const hideLoading = showLoading();
    dispatch(addPrograming(datos))
      .then(() => {
        toast.success("Programación actualizada");
      })
      .catch((errorr) => {
        toast.error(`un error ha ocurrido ${errorr}`);
      })
      .finally(() => {
        hideLoading();
        navigate("/admin");
      });
  };

  const onUpdate=(datos)=>{
     const hideLoading = showLoading();
     dispatch(updateProgramming(datos))
     .then((response)=>{
      console.log(response)
      toast.success("Programación actualizada");
     })
     .catch((errorr) => {
      toast.error(`un error ha ocurrido ${errorr}`);
    })
    .finally(() => {
      hideLoading();
      navigate("/admin");
    });
  }

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Envios</li>
                <li>Programaciones</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item" style={{ display: "none" }} />
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <ScheduleForm
          submitForm={onSubmit}
          updateForm={onUpdate}
          getProfiles={getProfiles}
          getCampaignInfo={getCampaignInfo}
          dataCampaigns={listCampaign.Campaigns}
          dataNewsletters={dataNewsletters.Newsletter}
          dataSends={sendsByCampaign}
          initialValue={defaultValue}
          preselectedCampaign={selectedCampaign}
        />
      </section>
    </>
  );
};
export default ProgrammingForm;
