import { InferType } from "yup";
import Picker from "@emoji-mart/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as GsmCharsetUtils from '@trt2/gsm-charset-utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { Campaign, Newsletter, Sends } from "interfaces/definition";
import { ScheduledSchema, Range, MonthlyRepeat } from "schemas/formSchema";
import { Controller, SubmitHandler, useForm, FormProvider } from "react-hook-form";
import { ImagenPreview } from "components";
import { useAppDispatch } from "store";
import { URLSHORTER } from "features/sends/sendThunks";
import moment from "moment";
import daysOfWeek from "../../data/day_of_week.json";
import mockup from "../../assets/img/mock.jpg";
import tagInfo from "../../data/user_tag.json";
import { WAform } from "./WAForm";



type ScheduledInput = InferType<typeof ScheduledSchema>;

interface FormProps{
  submitForm:(data:ScheduledInput) => void,
  updateForm:(data:ScheduledInput) => void,
  getProfiles:(campaignId)=> void,
  getCampaignInfo:(campaignId)=> void,
  dataCampaigns:Campaign[],
  dataSends:Sends[],
  dataNewsletters:Newsletter[],
  initialValue?:ScheduledInput,
  preselectedCampaign?:string,
}

const ScheduleForm: React.FC<FormProps> = ({
  submitForm,
  updateForm,
  getProfiles,
  getCampaignInfo,
  dataCampaigns,
  dataSends,
  dataNewsletters,
  initialValue,
  preselectedCampaign,
}) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>(dataCampaigns ?? []);
  const [sends, setSends] = useState(dataSends);
  const [activeSendMethodTab, setActiveSendMethodTab] = useState<string>("email");
  const [newsletterAproved, setNewsletter] = useState(dataNewsletters ?? []);
  const [imagePreview, setImagePreview] = useState(mockup);
  const [showEmoji, setshowEmoji] = useState<boolean>(false);
  const [showEmojiHd, setshowEmojiHd] = useState<boolean>(false);
  const [numOfSMS, setNumOfSMS] = useState(0);
  const [charsCount, setCharCount] = useState(0);
  const [showShortenOption, setShowShortenOption]= useState(false)

  const methods = useForm<ScheduledInput>({
    resolver: yupResolver(ScheduledSchema),
  });

  const dispatch = useAppDispatch();
  const smsText = methods.watch("send_method.sms_message") ?? ""
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const enabledRepeatSending = methods.watch("repeat_sending");
  const interval = methods.watch("range");
  const [selectedCampaign, setSelectedCampaign] = useState(
    preselectedCampaign ?? ""
  );

  const detectUrl = (text) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;  // Detectar URLs
    return text.match(urlPattern);
  };

  const isUrlShortened = (url) => {
    const shortenedPatterns = [/sh\.kuvadi\.co/];  // Puedes agregar más patrones
    return shortenedPatterns.some((pattern) => pattern.test(url));
  };

  useEffect(() => {
    const detectedUrls = detectUrl(smsText)
    if (detectedUrls) {
      detectedUrls.forEach(async (url) => {
        if (!isUrlShortened(url)) {
          dispatch(URLSHORTER({url}))
          .then((response) => {
            const {data} =response.payload
            methods.setValue("send_method.sms_message", smsText.replace(url, data))
          })
          .catch((err) => {
            toast.error(`un error ha ocurrido ${err}`);
          })
         
        }
      });
      setShowShortenOption(true);
    }

    const { charCount, msgCount } = GsmCharsetUtils.getCharCount(smsText);
    setCharCount(charCount);
    setNumOfSMS(msgCount)
  }, [smsText])

  const resetTabValue = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        methods.setValue("repeat_each_month", undefined);
        methods.setValue("day_the_month", undefined);
        methods.setValue("ordinal", undefined);
        methods.setValue("weekday", undefined);
        break;
      case 2:
        methods.setValue("sending_just_x_days", []);
        break;
      default:
        methods.setValue("repeat_each_month", undefined);
        methods.setValue("day_the_month", undefined);
        methods.setValue("ordinal", undefined);
        methods.setValue("weekday", undefined);
        methods.setValue("sending_just_x_days", []);
        methods.setValue("sending_time", undefined);
        methods.setValue("date_end",undefined)
        methods.setValue("range", undefined);
    }
  };

  useEffect(() => {
    if (initialValue != null) {
      const data = initialValue as ScheduledInput;
      getCampaignInfo(data.profile);
      methods.setValue("identifier", data.identifier);
      methods.setValue("name", data.name);
      methods.setValue("active", data.active);
      methods.setValue("date_start", new Date(data.date_start));
      methods.setValue("day_the_month", data.day_the_month);
      methods.setValue("ordinal", data.ordinal);
      methods.setValue("profile", data.profile);
      methods.setValue("repeat_each_month", data.repeat_each_month);
      methods.setValue("repeat_sending", data.repeat_sending);
      methods.setValue("sending_just_x_days", data.sending_just_x_days);
      methods.setValue("send_method",data?.send_method ?? {})
      methods.setValue("date_end",data.date_end ? new Date(data.date_end): undefined)
      methods.setValue(
        "sending_time",
        data.sending_time ? new Date(data.sending_time) : undefined
      );
      methods.setValue("weekday", data.weekday);
      setIsEdit(true);
      setActiveSendMethodTab(data.send_method.type ?? "email")
      setActiveTab(data.range ?? 1)
    }
  }, [initialValue, newsletterAproved, campaigns, sends]);

  useEffect(() => {
    if (preselectedCampaign !== undefined) {
      getProfiles(preselectedCampaign);
      setSelectedCampaign(preselectedCampaign);
    }
  }, [preselectedCampaign]);

  useEffect(() => {
    setSends(dataSends);
  }, [dataSends]);

  useEffect(() => {
    setNewsletter(dataNewsletters ?? []);
  }, [dataNewsletters]);

  useEffect(() => {
    switch (activeTab) {
      case 1:
        methods.setValue("range", Range.DAILY);
        break;
      case 2:
        methods.setValue("range", Range.MONTHLY);
        break;
      default:
        methods.setValue("range", Range.NONE);
    }
    if (enabledRepeatSending === false) {
      resetTabValue(3);
    }
  }, [enabledRepeatSending, activeTab]);

  useEffect(() => {
    switch (interval) {
      case Range.MONTHLY:
        setActiveTab(2);
        break;
      default:
        setActiveTab(1);
    }
  }, [interval]);

  useEffect(() => {
    
    const defaultValues = {
      newsletter_id: "",
      preheader: "",
      respond_email: "",
      sender: "",
      sender_email: "",
      sms_message: "",
      subject: "",
      wa_phone_number:"",
      wa_template_id:"",
      wa_parameters:""
    };

    let typeValue = "email"; 
    switch (activeSendMethodTab) {
      case "sms":
        typeValue = "sms";
        break;
      case "wa":
        typeValue = "wa";
        break;
      default:
        typeValue = "email"; 
    }

    methods.setValue("send_method", {
      ...defaultValues,
      type: typeValue,
    });
  }, [activeSendMethodTab]);

  const handleTabSendMethodClick = (tabNumber) => {
    setActiveSendMethodTab(tabNumber);
  };

  const newsletterPreview = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;

    if (newsletterAproved) {
      const imgUrl = newsletterAproved
        .filter((w) => selectedOption === w.uuid)
        .map((w) => w.img as string);
      if (imgUrl.length !== 0) setImagePreview(imgUrl[0]);
      else setImagePreview(mockup);
    }
  };

  const setEmojiValuePreheader = (moji) => {
    const field = methods.getValues("send_method.preheader");
    methods.setValue("send_method.preheader", `${field} ${moji.native}`);
  };

  const openEmojiPanelPreheader = (e) => {
    e.stopPropagation();
    setshowEmojiHd(!showEmojiHd);
  };

  const setEmojiValueSubject = (moji) => {
    const field = methods.getValues("send_method.subject");
    methods.setValue("send_method.subject", `${field} ${moji.native}`);
  };

  const openEmojiPanelSubject = (e) => {
    e.stopPropagation();
    setshowEmoji(!showEmoji);
  };

  const insertTagValues = ({ target }) => {
    const field = methods.getValues("send_method.subject");
    methods.setValue("send_method.subject", `${field} ${target.value}`);
  };

  const insertTagSMSValues = ({ target }) => {
    const beforeValue = methods.getValues("send_method.sms_message")
    methods.setValue("send_method.sms_message", `${beforeValue} ${target.value}`)
  };

  const onSubmit: SubmitHandler<ScheduledInput> = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (!isEdit) {
      submitForm(data);
    } else {
      updateForm(data);
    }

    methods.reset();
  };

  const getProfileList = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSelectedCampaign(selectedOption);
    getProfiles(selectedOption);
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    resetTabValue(tabNumber);
  };

  const generateCustomDataSelect = (length: number, text) => {
    const component: JSX.Element[] = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= length; i++) {
      component.push(
        <option key={i} value={i}>
          {text.replace("$", i)}
        </option>
      );
    }

    return component;
  };

  const cancelHandleClick = () => {
    navigate(`/admin/sends/scheduled-list`);
  };

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="fa-solid fa-list" />
            </span>
            Configuración del envio
          </p>
        </header>
        <div className="card-content">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Nombre</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded">
                  <input
                    autoComplete="false"
                    type="text"
                    id="form6Example1"
                    className={`input ${methods.formState.errors.name ? "is-danger" : ""}`}
                    {...methods.register("name")}
                  />
                </p>
                <p className="help is-danger">{methods.formState.errors.name?.message}</p>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Perfil</label>
            </div>
            <div className="field-body">
              <div className="field is-narrow">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className={`is-capitalized ${
                        methods.formState.errors.profile && "is-danger"
                      }`}
                      aria-label="campañas"
                      id="campaign"
                      onChange={(event) => getProfileList(event)}
                      value={selectedCampaign}>
                      <option value="">--Seleccione una opción--</option>
                      {campaigns.map((option, index) => (
                        <option
                          key={`campaign-${index + 1}`}
                          value={option.identifier}>
                          {`${option.name} - Fecha Inicio:  ${moment(
                            option.date_start
                          ).format("L")}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <p className="help is-danger">{methods.formState.errors.profile?.message}</p>
              </div>
              <div className="field is-narrow">
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      className={`is-capitalized ${
                        methods.formState.errors.profile ? "is-danger" : ""
                      }`}
                      aria-label="Default select example"
                      id="form6Example1"
                      {...methods.register("profile", {
                        required: true,
                        valueAsNumber: true,
                      })}>
                      <option value="">--Seleccione una opción--</option>
                      {sends.map((send, index) => (
                        <option key={`campaign-${index + 1}`} value={send.id}>
                          {`${send.name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Fecha de Inicio</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control is-expanded">
                  <Controller
                    name="date_start"
                    control={methods.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        minDate={new Date()}
                        
                        timeInputLabel="Hora:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        timeIntervals={5}
                        showTimeSelect
                        filterTime={filterPassedTime}
                        selected={field.value}
                        placeholderText="fecha inicial"
                        onChange={(selectdate) =>
                          field.onChange(selectdate)
                        }
                        className={`input ${methods.formState.errors.date_start && "is-danger"}`}
                        autoComplete="off"
                        isClearable
                        withPortal
                      />
                    )}
                  />
                </div>
                <p className="help is-danger">{methods.formState.errors.date_start?.message}</p>
              </div>
            </div>
            
          </div>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Opciones de Envio</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <label className="checkbox mt-2">
                    <input
                      className="checkbox mr-2"
                      type="checkbox"
                      role="switch"
                      id="repeatDelivery"
                      {...methods.register("repeat_sending")}
                    />
                    Habilitar repetición de envio
                  </label>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox mt-2">
                    <input
                      className="checkbox mr-2"
                      type="checkbox"
                      {...methods.register("active")}
                    />
                    Programar una vez guardada
                  </label>
                </div>
              </div>
            </div>
          </div>

          {enabledRepeatSending && (
            <>
              <hr />
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Hora de envio y fecha final</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control has-icons-left">
                      <Controller
                        name="sending_time"
                        control={methods.control}
                        render={({ field }) => (
                          <DatePicker
                            id="repeatSendHour"
                            showTimeSelect
                            showTimeSelectOnly
                            onChange={(selectdate) =>
                              field.onChange(selectdate)
                            }
                            className={`input ${
                              methods.formState.errors.sending_time ? "is-danger" : ""
                            }`}
                            timeIntervals={30}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            selected={field.value}
                            isClearable
                            withPortal
                          />
                        )}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-regular fa-clock" />
                      </span>
                    </p>

                    <p className="help is-danger">
                      {methods.formState.errors.date_start?.message}
                    </p>
                  </div>
                  <div className="field">
                <p className="control is-expanded">
                  <Controller
                    name="date_end"
                    control={methods.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <DatePicker
                        minDate={new Date()}
                        dateFormat="MM/dd/yyyy"
                        selected={field.value}
                        placeholderText="fecha final"
                        onChange={(selectdate) => field.onChange(selectdate)}
                        className={`input ${methods.formState.errors.date_end && "is-danger"}`}
                        autoComplete="off"
                        isClearable
                        withPortal
                      />
                    )}
                  />
                </p>
                <p className="help is-danger">{methods.formState.errors.date_end?.message}</p>
              </div>
                </div>
              </div>
              <div className="tabs is-boxed">
                <ul>
                  <li className={activeTab === 1 ? "is-active" : ""}>
                    <a href="#/" onClick={() => handleTabClick(1)}>
                      Dias de repetición
                    </a>
                  </li>
                  <li className={activeTab === 2 ? "is-active" : ""}>
                    <a href="#/" onClick={() => handleTabClick(2)}>
                      Mensual
                    </a>
                  </li>
                </ul>
              </div>
              <div className="columns">
                <div className="column is-full">
                  {activeTab === 1 && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Dias de envio</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-grouped is-grouped-multiline pt-2">
                          {
                            daysOfWeek.map((day,index)=>(
                              <p className="control" key={`k-${index+1}`}>
                              <label className="checkbox">
                                <input
                                  className="mr-2"
                                  type="checkbox"
                                  value={day.value}
                                  {...methods.register("sending_just_x_days")}
                                />
                                {day.name}
                              </label>
                            </p>
                            ))
                          }
                          {methods.formState.errors.sending_just_x_days && (
                            <p className="help is-success">
                              Debe indicar los dias en que se repetira el envio.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {activeTab === 2 && (
                    <div>
                      <div className="field field is-horizontal">
                        <div className="field-label is-normal">
                          <label className="label">Cada mes el</label>
                        </div>
                        <div className="field-body">
                          <div className="field has-addons">
                            <p className="control mt-2">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  id="flexRadioPrimerDia"
                                  value={MonthlyRepeat.FIRST_DAY}
                                  {...methods.register("repeat_each_month")}
                                />
                                Primer dia del mes
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="field field is-horizontal">
                      <div className="field-label is-normal">
                          <label className="label"/>
                        </div>
                        <div className="field-body">
                          <div className="field has-addons">
                            <p className="control mt-2">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  value={MonthlyRepeat.FIRST_BUSINESS_DAY}
                                  id="businessDay"
                                  {...methods.register("repeat_each_month")}
                                />
                                Primer dia habil del mes
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="field field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field has-addons">
                            <p className="control mt-2">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  value={MonthlyRepeat.LAST_DAY}
                                  id="lastDay"
                                  {...methods.register("repeat_each_month")}
                                />
                                Ultimo dia del mes
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="field field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field has-addons">
                            <p className="control mt-2">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  value={MonthlyRepeat.LAST_BUSINESS_DAY}
                                  id="lastBusinessDay"
                                  {...methods.register("repeat_each_month")}
                                />
                                Ultimo dia habil del mes
                              </label>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="field field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field">
                            <div className="control mt-2">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  value={MonthlyRepeat.SELECTED_DAY}
                                  id="dayOptionOne"
                                  {...methods.register("repeat_each_month")}
                                />
                                El dia
                              </label>
                            </div>
                          </div>
                          <div className="field  is-narrow">
                            <div className="control">
                              <div className="select is-fullwidth">
                                <select
                                  className={` ${
                                    methods.formState.errors.day_the_month ? "is-danger" : ""
                                  }`}
                                  disabled={ `${methods.watch("repeat_each_month")}` !== `${MonthlyRepeat.SELECTED_DAY}`}
                                  aria-label="rMontlyOptOneDay"
                                  id="rMontlyOptOneDay"
                                  {...methods.register("day_the_month", {
                                    valueAsNumber: true,
                                  })}>
                                  <option
                                    hidden
                                    defaultValue=""
                                    aria-label="seleccionar"
                                  />
                                  {generateCustomDataSelect(
                                    30,
                                    " $ del mes "
                                  ).map((value) => value)}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="field field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <div className="field">
                            <p className="control">
                              <label className="radio">
                                <input
                                  className="mr-2"
                                  type="radio"
                                  value={MonthlyRepeat.DAY_OF_WEEK}
                                  id="dayOptionTwo"
                                  {...methods.register("repeat_each_month")}
                                />
                                El dia
                              </label>
                            </p>
                          </div>
                          <div className="field  is-narrow">
                            <p className="control">
                              <div className="select is-fullwidth">
                                <select
                                  className={` ${
                                    methods.formState.errors.ordinal ? "is-danger" : ""
                                  }`}
                                  aria-label="rMontlyOptTwoCount"
                                  id="rMontlyOptTwoCount"
                                  disabled={
                                   `${methods.watch("repeat_each_month")}` !== `${MonthlyRepeat.DAY_OF_WEEK}`
                                  }
                                  {...methods.register("ordinal",{
                                    valueAsNumber:true
                                  })}>
                                  <option
                                    hidden
                                    defaultValue=""
                                    aria-label="seleccionar"
                                  />
                                  <option value="0">Primer</option>
                                  <option value="1">Segundo</option>
                                  <option value="2">Tercer</option>
                                  <option value="3">Cuarto</option>
                                  <option value="4">Quinto</option>
                                </select>
                              </div>
                            </p>
                          </div>
                          <div className="field  is-narrow">
                            <p className="control is-expanded">
                              <div className="select is-fullwidth">
                                <select
                                  className={` ${
                                    methods.formState.errors.weekday ? "is-danger" : ""
                                  }`}
                                  aria-label="rMontlyOptTwoDayWeek"
                                  id="camprMontlyOptTwoDayWeekaign"
                                  disabled={
                                    `${methods.watch("repeat_each_month")}` !== `${MonthlyRepeat.DAY_OF_WEEK}`
                                  }
                                  {...methods.register("weekday",{
                                    valueAsNumber:true
                                  })}>
                                  <option
                                    hidden
                                    defaultValue=""
                                    aria-label="seleccionar"
                                  />
                                  <option value="0">Lunes</option>
                                  <option value="1">Martes</option>
                                  <option value="2">Miercoles</option>
                                  <option value="3">Jueves</option>
                                  <option value="4">Viernes</option>
                                  <option value="5">Sabado</option>
                                  <option value="6">Domingo</option>
                                </select>
                              </div>
                            </p>
                          </div>
                        </div>
                      </div>
                      {methods.formState.errors.repeat_sending && (
                        <p className="help is-success">
                          Debe seleccionar una opción de envio mensual.
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <fieldset>
      <div className="card">
        <header className="card-header">
          <p className="card-header-title">
            <span className="icon">
              <i className="fa-regular fa-paper-plane" />
            </span>
            Metodo de envio
          </p>
        </header>
        <div className="card-content">
          <div className="tabs is-boxed">
            <ul>
              <li className={activeSendMethodTab === "email" ? "is-active" : ""}>
                <a href="#/" onClick={() => handleTabSendMethodClick("email")}>
                  <span className="icon is-small">
                    <i className="fa-solid fa-envelope" />
                  </span>
                  <span>Email</span>
                </a>
              </li>
              <li className={activeSendMethodTab === "sms" ? "is-active" : ""}>
                <a href="#/" onClick={() => handleTabSendMethodClick("sms")}>
                  <span className="icon is-small">
                    <i className="fa-solid fa-comment-sms" />
                  </span>
                  <span>SMS</span>
                </a>
              </li>
              <li className={activeSendMethodTab === "wa" ? "is-active" : ""}>
                <a href="#/" onClick={() => handleTabSendMethodClick("wa")}>
                  <span className="icon is-small">
                    <i className="fa-brands fa-whatsapp" />
                  </span>
                  <span>Whatsapp</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="columns">
            <div className="column is-full">
              {activeSendMethodTab === "email" && (
                <>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Plantilla</label>
                    </div>
                    <div className="field-body">
                      <div className="field is-narrow">
                        <div className="control">
                          <div className="select is-fullwidth">
                            <select
                              className={methods.formState.errors.send_method?.newsletter_id && "is-danger"}
                              aria-label="newsletters"
                              id="newsletter"
                              {...methods.register("send_method.newsletter_id")}
                              onChange={(event) => newsletterPreview(event)}>
                              <option value="">--Seleccione una opción--</option>
                              {newsletterAproved.map((option, index) => (
                                <option
                                  key={`news-${index + 1}`}
                                  value={option.uuid}>
                                  {`${option.name}`}
                                </option>
                              ))}
                            </select>
                          </div>
                          <p className="help is-danger">
                            {methods.formState.errors.send_method?.newsletter_id?.message}
                          </p>
                        </div>
                      </div>
                      <div className="field is-narrow">
                        <div className="control">
                          <div className="media">
                            <figure className="media-left">
                              <div className="image">
                                <ImagenPreview
                                  imagenUrl={imagePreview}
                                  description="Descripción de la imagen"
                                />
                              </div>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Nombre y correo de origen</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="text"
                            id="sender"
                            autoComplete="false"
                            placeholder="Nombre"
                            className={`input ${methods.formState.errors.send_method?.sender && "is-danger"}`}
                            {...methods.register("send_method.sender")}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-regular fa-user" />
                          </span>
                        </p>
                        <p className="help is-danger">{methods.formState.errors.send_method?.sender?.message}</p>
                      </div>
                      <div className="field">
                        <p className="control is-expanded has-icons-left">
                          <input
                            type="email"
                            autoComplete="false"
                            id="senderemail"
                            placeholder="Email"
                            className={`input ${methods.formState.errors.send_method?.sender_email && "is-danger"
                              }`}
                            {...methods.register("send_method.sender_email")}
                          />
                          <span className="icon is-small is-left">
                            <i className="fa-solid fa-at" />
                          </span>
                        </p>
                        <p className="help is-danger">
                          {methods.formState.errors.send_method?.sender_email?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Correo de Respuesta</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <p className="control is-expanded">
                          <input
                            autoComplete="false"
                            type="text"
                            id="response"
                            className={`input ${methods.formState.errors.send_method?.respond_email && "is-danger"
                              }`}
                            {...methods.register("send_method.respond_email")}
                          />
                        </p>
                        <p className="help is-danger">
                          {methods.formState.errors.send_method?.respond_email?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Preheader</label>
                    </div>
                    <div className="field-body">
                      <div className="field has-addons">
                        <div className="control is-expanded">
                          <input
                            autoComplete="false"
                            type="text"
                            id="preheader"
                            className={`input ${methods.formState.errors.send_method?.preheader && "is-danger"}`}
                            {...methods.register("send_method.preheader", { required: true })}
                          />
                        </div>
                        <div className="control">
                          <div className="form-outline">
                            <div className="cover-emoji-right">
                              {showEmojiHd && (
                                <Picker
                                  onEmojiSelect={(emoji) =>
                                    setEmojiValuePreheader(emoji)
                                  }
                                  locale="es"
                                  skinTonePosition="preview"
                                  autoFocus="true"
                                  emojiButtonSize="32"
                                  previewPosition="none"
                                  onClickOutside={openEmojiPanelPreheader}
                                />
                              )}
                            </div>

                            <button
                              type="button"
                              className="button"
                              onClick={openEmojiPanelPreheader}>
                              <i className="fa-regular fa-face-smile-beam" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">Asunto</label>
                    </div>
                    <div className="field-body">
                      <div className="field has-addons">
                        <div className="control is-expanded">
                          <textarea
                            rows={5}
                            className={`textarea ${methods.formState.errors.send_method?.subject && "is-danger"
                              }`}
                            aria-label="Introduce un comentario"
                            placeholder="Puede usar las etiquetas de la izquierda para personalizar el asunto."
                            {...methods.register("send_method.subject")}
                          />
                        </div>
                        <div className="control">
                          <div className="form-outline">
                            <div className="cover-emoji-right">
                              {showEmoji && (
                                <Picker
                                  onEmojiSelect={(emoji) =>
                                    setEmojiValueSubject(emoji)
                                  }
                                  locale="es"
                                  skinTonePosition="preview"
                                  autoFocus="true"
                                  emojiButtonSize="32"
                                  previewPosition="none"
                                  onClickOutside={openEmojiPanelSubject}
                                />
                              )}
                            </div>
                            <button
                              type="button"
                              className="button"
                              onClick={openEmojiPanelSubject}>
                              <i className="fa-regular fa-face-smile-beam" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="field is-narrow">
                        <div className="control">
                          <div className="select is-fullwidth is-multiple">
                            <select
                              size={4}
                              multiple
                              className="form-select w-30 form-select-sm"
                              onChange={insertTagValues}>
                              <option
                                hidden
                                defaultValue=""
                                aria-label="seleccionar"
                              />
                              {tagInfo.map((option, index) => (
                                <option
                                  key={`datatag-${index + 1}`}
                                  value={option.value}>
                                  {`${option.name}`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeSendMethodTab === "sms" && (
                <div className="container is-flex is-justify-content-center mt-6">
                  <div className="columns is-centered">
                    <div className="column is-two-thirds">
                      <h4 className="title is-4">Editor de SMS</h4>
                      <div className="field">
                        <label className="label">Mensaje</label>
                        <div className="control">
                          <textarea
                            {...methods.register('send_method.sms_message')}
                            placeholder="Escribe tu mensaje de texto aquí..."
                            rows={5}
                            cols={40}
                            className={`textarea ${methods.formState.errors.send_method?.sms_message && "is-danger"}`}
                          />
                        </div>
                      </div>
                      <div className="field is-narrow">
                        <label className="label">Tags de integración</label>
                        <div className="control">
                          <div className="select is-fullwidth is-multiple">
                            <select
                              size={4}
                              multiple
                              className="form-select w-30 form-select-sm"
                              onChange={insertTagSMSValues}
                            >
                              <option hidden defaultValue="" aria-label="seleccionar" />
                              {tagInfo.map((option, index) => (
                                <option key={`datatag-${index + 1}`} value={option.value}>
                                  {`${option.name}`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <p className="help is-info">Cuando usa tags el numero de mensajes es aproximado. Debe tener encuenta el valor de la información registrada.</p>
                      </div>
                      <nav className="level">
                        <div className="level-left">
                          <div className="level-item">
                            <p>Número de mensajes SMS: {numOfSMS}</p>
                          </div>
                        </div>
                        <div className="level-right">
                          <div className="level-item">
                            <p>Caracteres: {charsCount}</p>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div className="column is-flex is-justify-content-center">
                      <div className="phone-frame">
                        <div className="phone-content">
                          <div className="message-content">
                            {smsText.length > 0 ? (
                              <>
                                <div className="message-bubble">
                                  <p>{smsText}</p>
                                </div>
                                <div className="sender-info">Envia: John Doe</div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {activeSendMethodTab === "wa" && (
                <WAform/>
              )}
            </div>
          </div>
        </div>
      </div>
      </fieldset>
      <nav className="level mb-6 p-4 has-background-white">
        <div className="level-left">
          <div className="level-item">
            <p className="subtitle is-7">
              Si no se selecciona la opción &quot;Programar una vez
              guardada&quot;, la programación solo se guardara.
            </p>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="field is-horizontal">
              <div className="field-label" />
              <div className="field-body">
                <div className="field">
                  <div className="field is-grouped">
                    <div className="control">
                      <button type="submit" className="button is-primary">
                        <span>{isEdit ? "Actualizar" : "Guardar"}</span>
                      </button>
                    </div>
                    <div className="control">
                      <button
                        type="button"
                        className="button is-primary is-outlined"
                        onClick={() => methods.reset()}>
                        <span>Limpiar</span>
                      </button>
                    </div>
                    {isEdit && (
                      <div className="control">
                        <button
                          type="button"
                          className="button is-primary is-outlined"
                          onClick={() => cancelHandleClick()}>
                          <span>Cancelar</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </form>
    </FormProvider>
  );
};

export default ScheduleForm;
